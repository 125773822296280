var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      _c("div", { staticClass: "cropper-content" }, [
        _c(
          "div",
          { staticClass: "cropper" },
          [
            _c("vueCropper", {
              ref: "cropper",
              attrs: {
                img: _vm.img,
                outputSize: _vm.option.size,
                outputType: _vm.option.outputType,
                info: true,
                full: _vm.option.full,
                canMove: _vm.option.canMove,
                canMoveBox: _vm.option.canMoveBox,
                original: _vm.option.original,
                autoCrop: _vm.option.autoCrop,
                autoCropWidth: _vm.option.autoCropWidth,
                autoCropHeight: _vm.option.autoCropHeight,
                fixedBox: _vm.option.fixedBox
              },
              on: { realTime: _vm.realTime, imgLoad: _vm.imgLoad }
            })
          ],
          1
        ),
        _c("div", { staticClass: "right" }, [
          _c("div", { staticClass: "show-preview", style: _vm.previewStyle }, [
            _c("div", { staticClass: "avatar", style: _vm.previews.div }, [
              _c("img", {
                style: _vm.previews.img,
                attrs: { src: _vm.previews.url }
              })
            ])
          ]),
          _c("div", { staticClass: "show-preview", style: _vm.previewStyle1 }, [
            _c("div", { staticClass: "avatar", style: _vm.previews.div }, [
              _c("img", {
                style: _vm.previews.img,
                attrs: { src: _vm.previews.url }
              })
            ])
          ]),
          _c("div", { staticClass: "scope-btn" }, [
            _c(
              "label",
              {
                staticClass: "el-button el-button--primary el-button--small",
                attrs: { for: "uploads" }
              },
              [_vm._v("选择图片")]
            ),
            _c("input", {
              staticStyle: { position: "absolute", clip: "rect(0 0 0 0)" },
              attrs: {
                type: "file",
                id: "uploads",
                accept: "image/png, image/jpeg, image/jpg"
              },
              on: { change: _vm.fileChange }
            })
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "padding-top text-right" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  return _vm.$emit("cancle")
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.handleSave }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }